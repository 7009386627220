import { client, redirectToConversation } from './api';
import { get, startCase } from 'lodash';
import { isAxiosError } from 'axios';

export const state = {
  pharmacy_name: 'Unknown Pharmacy',

  customer: null,
  order: {
    billed: false,
    cancelled: false,
    delivery_charge: 0,
    doctor: {
      medicines: {}
    }
  },

  ui: {
    disabled: false,
    processing: false,
  },

  cart: [],

  batchSearch: {
    item: null,
    results: [],
    isLoading: false,
    isNotFound: false,
    isOpen: false,
  },

  search: {
    results: [],
    isLoading: false,
    isNotFound: false,
    isOpen: false,
  },
};

export const getters = {};

export const actions = {
  async fetchOrderDetails({ rootGetters, state, dispatch }) {
    const conversation = rootGetters.getSelectedChat;
    const user = rootGetters.getCurrentUser

    client.order_id = conversation.id;

    const attrs = await client.getCustomAttributes(conversation.id);

    state.pharmacy_name = attrs?.serving_pharmacy?.name || 'Unknown Pharmacy';

    const distance =
      Number(attrs?.customer_pharmacy_distance || 0) / 1000 + ' Kms';

    state.order = {
      billed: false,
      cancelled: false,
      delivery_charge: 0,
      doctor: {
        medicines: {}
      }
    };

    state.customer = {};

    state.order.order_id = conversation.id;
    state.order.account_id = conversation.account_id;
    state.order.distance = distance;

    state.order.discount_percentage = get(
      attrs,
      'discount_percentage',
      0
    );

    state.order.delivery_charge = get(attrs, 'delivery_charge', 0) / 100;

    state.customer.address = get(attrs, 'customer_address', '');
    state.customer.name = get(attrs, 'billing_name');

    if (state.customer.name) {
      const [customer, doctor] = state.customer?.name?.split('RMP')
      state.customer.name = customer?.trim()
      state.customer.doctor = doctor?.trim()
    }

    if (user.custom_attributes.agent_type === 'DOCTOR') {
      state.customer.doctor = user.name
    }

    switch (attrs?.bill_status) {
      case 'billed':
        state.order.billed = true;
        state.ui.disabled = true;
        return console.error(`Order Already billed`);

      case 'cancelled':
        state.order.cancelled = true;
        state.ui.disabled = true;
        return console.error(`Order Cancelled`);

      default:
        state.order.billed = false;
        state.ui.disabled = false;
        state.ui.processing = false;
    }

    await dispatch('fetchCart');
  },

  async fetchCart({ rootGetters, state }) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    const cart = await client.getCart(conversation.id);

    const medicines_meta = {}

    for (const item of cart) {
      const key = `${item.medicine_id}|${item.batch}`
      medicines_meta[key] = state.order.doctor.medicines[key] ?? { dosage: '-', frequency: 1 }
    }

    state.order.doctor.medicines = medicines_meta

    state.cart = cart;
  },

  async savePrescription({rootGetters, state, dispatch}, payload) {
    const conversation = rootGetters.getSelectedChat

    await dispatch('fetchCart')

    const medicines = state.cart.map(item => {
      const k = `${item.medicine_id}|${item.batch}`
      const m = state.order.doctor.medicines[k]

      if (m.dosage === '-' || m.frequency === 0) {
        throw new Error(`Invalid dosage or frequency`)
      }

      item.dosage = m.dosage
      item.frequency = m.frequency

      return item
    })

    await client.savePrescription(conversation.id, {...payload, medicines})
  },

  async searchMedicine({ rootGetters, state }, query) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.search.isLoading = true;
    state.search.isNotFound = false;
    state.search.isOpen = false;

    const items = await client.searchMedicines(query);

    for (const item of items) {
      const existing = state.cart.find(i => i.id === item.medicine_id);

      const availability = item.loose_quantity > (existing ? existing.quantity : 0);

      if (existing) existing.isAvailable = availability;

      item.isAvailable = availability;
    }

    state.search.isLoading = false;

    state.search.results = items.sort((a, b) => b.isAvailable - a.isAvailable);

    if (items.length === 0) {
      return (state.search.isNotFound = true);
    }

    state.search.isOpen = true;
  },

  async sendMedicineDetail({ rootGetters, state }, medicine) {
    if (medicine.discontinued === "yes") return alert('Medicine is discontinued - cannot send details');

    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.ui.processing = true;

    await client.sendMedicineDetail(medicine.slug);

    state.ui.processing = false;
    state.search.isOpen = false;
    window.CHATWOOT_CHANGE_TAB(0);
  },

  async searchAlternateMedicine({ rootGetters, state }, id) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.search.isLoading = true;
    state.search.isNotFound = false;
    state.search.isOpen = false;

    const items = await client.searchAlternate(id);

    for (const item of items) {
      const existing = state.cart.find(i => i.id === item.medicine_id);

      const availability = item.loose_quantity > (existing ? existing.quantity : 0);

      if (existing) existing.isAvailable = availability;

      item.isAvailable = availability;
    }

    state.search.isLoading = false;

    state.search.results = items.sort((a, b) => b.isAvailable - a.isAvailable);

    if (items.length === 0) {
      return (state.search.isNotFound = true);
    }

    state.search.isOpen = true;
  },

  async addToShortbook({ rootGetters, state }, data) {
    try {
      state.ui.processing = true;

      const res = await client.addToShortbook(data);
    } catch (error) {
      console.log(error);
    } finally {
      state.ui.processing = false;
    }
  },

  async patchCartItem({ rootGetters, state, dispatch }, item) {
    try {
      state.ui.processing = true;

      const conversation = rootGetters.getSelectedChat;

      client.order_id = conversation.id;

      const patch = {
        batch: item.batch,
        discount: item.discount ?? 0,
        medicine_id: item.medicine_id,
        quantity: Number(item.quantity),
      };

      const existing = state.cart.find(
        i => i.medicine_id === patch.medicine_id && i.batch === patch.batch
      );

      if (existing) {
        patch.version = existing.version;
      }

      const next = await client.updateCartItems({
        conversation_id: conversation.id,
        items: [patch],
      });

      state.cart = next;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response.status === 409) {
          bus.$emit('newToastMessage', `Cart changed since last update - refetching`);
          await dispatch('fetchCart');
        }
      }
    } finally {
      state.ui.processing = false;
    }
  },

  async selectMedicineBatch({ state, rootGetters }, item) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.search.isOpen = false;

    state.batchSearch.item = item;
    state.batchSearch.isLoading = true;
    state.batchSearch.isOpen = false;
    state.batchSearch.isNotFound = false;

    const results = await client.getMedicineBatches(item.medicine_id);

    for (const result of results) {
      const existing = state.cart.find(
        i => i.id === item.medicine_id && i.batch === result.name
      );

      const available = result.quantity > (existing?.quantity ?? 0);

      result.isAvailable = available;
    }

    state.batchSearch.results = results;
    state.batchSearch.isLoading = false;
    state.batchSearch.isOpen = true;
  },

  async updateOrderDetails({ rootGetters, state }, { customer, order }) {
    const conversation = rootGetters.getSelectedChat;

    client.order_id = conversation.id;

    state.ui.processing = true;

    let final_doctor_name = customer?.doctor?.trim() ?? "";

    if (customer?.doctor?.trim().length !== 0) {
      const starts_with_doctor = customer?.doctor?.trim().toLowerCase().startsWith('dr');
      if (!starts_with_doctor) {
        final_doctor_name = `Dr. ${startCase(final_doctor_name.toLowerCase())}`
      }
      if (starts_with_doctor) {
        final_doctor_name = `Dr. ${startCase(final_doctor_name.toLowerCase().substring(2).trim())}`
      }
    }

    await client.updateCustomAttributes({
      customer_address: customer.address,
      billing_name: customer.doctor ? `${customer?.name?.trim()} RMP ${final_doctor_name}` : customer?.name?.trim(),
      delivery_charge: order.delivery_charge * 100,
    });

    state.ui.processing = false;
  },

  async duplicateBill({ state, rootGetters }) {
    const reason = prompt('Duplicating Your Order!! Enter your reason.');

    if (!reason || reason === '') {
      return alert('enter a valid reason');
    }

    try {
      const conversation = rootGetters.getSelectedChat;

      state.ui.processing = true;

      const data = await client.duplicateOrderVirtualRx({
        reason,
        conversation_id: conversation.id,
      });

      redirectToConversation(data.conversation_id);
    } catch (error) {
      console.log('ERROR: ', error);
    } finally {
      state.ui.processing = false;
    }
  },

  async duplicateOrder({ state, rootGetters }) {
    try {
      const conversation = rootGetters.getSelectedChat;
      state.ui.processing = true;

      const data = await client.duplicateOrder(conversation.id);

      redirectToConversation(data.conversation_id);
    } catch (error) {
      console.log(error);
    } finally {
      state.ui.processing = false;
    }
  },

  async cancelOrder({ state, rootGetters }) {
    const reason = prompt('Cancelling Your Order!! Enter your reason.');

    if (!reason || reason === '') {
      return alert('enter a valid reason');
    }

    try {
      const conversation = rootGetters.getSelectedChat;

      state.ui.processing = true;

      const data = await client.cancelOrder({
        reason,
        conversation_id: conversation.id,
      });

      redirectToConversation(data.conversation_id);
    } catch (error) {
      console.log(error.message);
    } finally {
      state.ui.processing = false;
    }
  },

  async placeOrder({ state, rootGetters }) {
    state.ui.processing = true;

    const conversation = rootGetters.getSelectedChat;

    const placed = await client.placeEvitalOrder(
      conversation.id,
      conversation.account_id
    );

    if (!placed) return;

    window.CHATWOOT_CHANGE_TAB(0);

    state.ui.processing = false;
  },


  async updatePrescription({ state, rootGetters }) {
    state.ui.processing = true;

    const conversation = rootGetters.getSelectedChat;

    const placed = await client.placeEvitalOrder(
      conversation.id,
      conversation.account_id
    );

    if (!placed) return;

    window.CHATWOOT_CHANGE_TAB(0);

    state.ui.processing = false;
  },

  placeCall({ state, rootGetters, dispatch }, payload) {
    const conversation = rootGetters.getSelectedChat;
    payload.order_id = conversation.id.toString()
    return client.placeCall(payload)
  },

  async updateDoctorMedicine({ state, rootGetters, dispatch }, payload) {
    const key = `${payload.medicine_id}|${payload.batch}`
    let med = state.order.doctor.medicines[key]

    if (!med) {
      await dispatch('fetchCart')
      med = state.order.doctor.medicines[key]
    }

    if (med && payload.dosage) med.dosage = payload.dosage
    if (med && payload.frequency) med.frequency = payload.frequency
  }
};

export const mutations = {
  clearSearch(state) {
    state.search.results = [];
    state.search.isLoading = false;
    state.search.isNotFound = false;
    state.search.isOpen = false;
  },
  closeBatchSelection(state) {
    state.batchSearch.results = [];
    state.batchSearch.isOpen = false;
    state.batchSearch.isLoading = false;
    state.batchSearch.isNotFound = false;
  },

};

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
};
